import axios from 'axios'
import router from '@/router'
import store from '@/store'

//创建axios实例
const service = axios.create({
  baseURL: 'http://119.29.177.247:8088/api',
})

service.interceptors.request.use(
  config => {
    if (store.state.token) {
      config.headers['token'] = window.sessionStorage.getItem("token")
    }
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    
    return config
  },
  error => {
    console.log(error) 
    return Promise.reject(error)
  }
)

service.interceptors.response.use(response => {
    let res = response.data;
    console.log(res)
    if (res.code === 200) {
      return response
    } else {
      return Promise.reject(response.data.msg)
    }
  },
  error => {
    console.log(error)
    if (error.response.data) {
      error.message = error.response.data.msg
    }

    if (error.response.status === 401) {  
      router.replace("/login")
    }
    return Promise.reject(error)
  }
)

export default service
