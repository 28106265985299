import Vue from 'vue'
import App from './App.vue'
import router,{resetRouter} from './router'
import store,{addNewRoute} from './store'
import './assets/css/reset.css'
import './plugins/element.js'
import './router/permission.js'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

axios.defaults.baseURL = 'http://119.29.177.247:8088/api'
//确保在vue 实例创建之前调用 resetRouter()
//   if (store.state.menus.length > 0) {
//   resetRouter(); // 确保在这里也调用 resetRouter()
//   addNewRoute(store.state.menus)
// }

new Vue({
  // el: '#app',
  router,
  store,
  // axios,
  render: h => h(App)
}).$mount('#app')
