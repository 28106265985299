<template>
  <div id="login">
    <el-form class="login-container" label-position="left" label-width="0px" :rules="loginRules" ref="loginForm"
      :model="loginForm">
      <h3 class="login_title">后台系统登录</h3>
      <el-form-item class="custom-form-item" prop="username">
        <i class="el-icon-user">账号</i>
        <el-input type="text" v-model="loginForm.username" auto-complete="off" placeholder="账号"></el-input>
      </el-form-item>
      <el-form-item class="custom-form-item" prop="password">
        <i class="el-icon-edit">密码</i>
        <el-input type="password" v-model="loginForm.password" auto-complete="off" placeholder="密码"
          show-password></el-input>
      </el-form-item>

      <el-form-item style="display: flex; justify-content: space-between; margin-top: 30px;;">
        <el-button type="primary" @click="resetForm">重置</el-button>
        <el-button type="primary" style="border: none" @click="login" :loading="loading">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'login',
  components: {
  },

  data() {
    return {
      loading: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 3, max: 15, message: '账号长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 18, message: '密码长度在 6 到 18 个字符', trigger: 'blur' }
        ],
      },
    };
  },
  methods: {
    resetForm() {
      this.loginForm = {
        username: "",
        password: ""
      };
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true; // 显示加载动画   
          setTimeout(() => {
            const { username, password } = this.loginForm; // 使用解构赋值获取表单数据  
            this.$store.dispatch('usersLogin', { username, password }) // 将登录信息发送到Vuex  
              .then(resp => {
                this.loading = false; // 登录请求发出后，隐藏加载动画  
                console.log('登录成功:', resp);
                this.$message.success("登录成功");
                // 等待动画隐藏后再进行页面跳转  
                setTimeout(() => {
                  var path = this.$route.query.redirect || '/'; // 使用逻辑或操作符简化代码  
                  this.$router.replace({ path: path });
                }, 500);
              })
              .catch(error => {
                this.loading = false; // 登录请求失败，隐藏加载动画  
                this.$message.error("账号或密码错误或者没打开后端");
              });
          }, 500);
        }
      })
    }
  }
}
</script>

<style scoped>
#login {
  /* background: url("../assets/img/bg.jpg") no-repeat; */
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
}

body {
  margin: 0px;
}

.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 90px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.login_title {
  margin: 0px auto 10px auto;
  text-align: center;
  color: #505458;
}

.custom-form-item {
  margin-bottom: 10px;
}
</style>