import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/index.vue'
import Layout from '../views/layout/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path:'/login',
    name:'Login',
    component: Login,
    meta: {title: '登录'},
  },
  {
    path: '/404',
    component: () => import('@/views/404'), 
  },
  {
    path: '/',
    component: Layout,
    meta: 
    {
    },
    redirect:'/home',
    children:[  
      {
        path: '/home',
        name: '个人中心',
        component: () => import('@/views/home/Home.vue'),
        meta: {title: '个人中心'},
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes, // 缺省的路由配置
});

export function resetRouter() {
  // 创建一个新的 VueRouter 实例，带有空的路由规则
  const newRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [], // 空的路由规则
  });
  // 将新路由器的 matcher 替换到当前的 router 实例上
  router.matcher = newRouter.matcher;
  // router.match = newRouter.match;
}
export default router
