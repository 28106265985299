<template>
  <div class="layout">
    <el-container style="height: 100vh; border: 0px solid #eee">
      <!--  -->
      <el-aside :style="{ width: asideWidth }" style="background-color: rgb(238, 241, 246)">
        <el-menu router :default-active="$route.path" :collapse="isCollapse" class="el-menu-vertical-demo"
          background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" style="height: 100%;">
          <el-menu-item index="/home">
            <i class="el-icon-setting"></i>
            <span slot="title">后台管理系统</span>
          </el-menu-item>
          <el-submenu v-for="menu in menusWithChildren" v-if="menu.hidden === 0" :key="menu.id" :index="menu.menucode">
            <template slot="title">
              <i :class="menu.menuicon"></i>
              <span>{{ menu.menutitle }}</span>
            </template>
            <el-menu-item v-for="child in menu.children" v-if="child.hidden === 0" :key="child.id"
              :index="child.menupath">
              {{ child.menutitle }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-container>
        <el-header style="height: 50px; display: flex; justify-content: space-between; align-items: center;">
          <div style="display: flex; align-items: center;">

            <div @click="changeCollapse">
              <i v-if="!isCollapse" class="el-icon-s-fold" style="margin: 0px; font-size: 25px; color: red;"></i>
              <i v-else class="el-icon-s-unfold" style="margin: 0px; font-size: 25px; color: green;"></i>
            </div>

            <Breadcrumb></Breadcrumb>
          </div>
          <div style="display: flex; align-items: center;">

            <el-dropdown>
              <span>更多</span><i class="el-icon-message-solid" style="margin-left: 5px;"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="toUser">个人中心</el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import Breadcrumb from '../../components/Breadcrumb.vue'
export default {
  name: 'Layout',
  components: {
    Breadcrumb
  },
  data() {
    return {
      isCollapse: false,
      asideWidth: '200.5px',
    }
  },
  computed: {
    ...mapState(['menus']),
    menusWithChildren() {
      return this.menus.filter(menu => menu.children && menu.children.length > 0);
    }
  },
  mounted() {
  },
  methods: {

    changeCollapse() {
      this.isCollapse = !this.isCollapse;
      this.asideWidth = this.isCollapse ? '64px' : '200.5px';
    },
    toUser() {
      console.log('to_user')
      this.$router.push("/home").catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
        console.log('Ignored navigation to current location:', err);
      });
    },

    logout() {
      console.log('logout')
      this.$confirm('您确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '退出登录成功'
          })
          this.$router.push("/login")
          this.$store.commit('REMOVE_INFO');
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出登录'
          })
        })
    }
  }
}; 
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.layout {
  overflow: hidden;
  height: 99vh;
}

.el-header {
  background-color: #edf4f7;
  /* color: #333; */
  line-height: 60px;
}

.el-aside {
  height: 99vh;
  /* color: #333; */
  transition: width 0.5s ease;
}
</style>