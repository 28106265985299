import Vue from 'vue'
import Vuex from 'vuex'
import { login } from '@/api/login'  
import router,{resetRouter} from "../router";

Vue.use(Vuex)

const store = new Vuex.Store({  
    state: {  
        token: sessionStorage.getItem("token") || '',  
        user: JSON.parse(sessionStorage.getItem("user")) || {},
        menus: JSON.parse(sessionStorage.getItem("menus")) || [],
        avatar: sessionStorage.getItem("avatar") || null
      },  
    mutations: {  
        SET_TOKEN: (state, token) => {  
            state.token = token  
            sessionStorage.setItem("token", token)  
        },  
        SET_USER: (state, user) => {  
            state.user = user  
            sessionStorage.setItem("user", JSON.stringify(user))  
        },
        REMOVE_INFO: (state) => {  
            state.token = ''  
            state.user = {}  
            state.menus = []
            sessionStorage.removeItem("token")  
            sessionStorage.removeItem("user")  
            sessionStorage.removeItem("menus") 
        },
        SET_MENUS(state, menus) {  
        state.menus = menus;   
        sessionStorage.setItem('menus', JSON.stringify(menus)); 
    },
      SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
        sessionStorage.setItem("avatar", avatar);
      }
    },  
    getters: {  
    },  
    actions: {  
        async usersLogin({ commit }, { username, password }) {  
            try {
                const resp = await login({ username, password });  
                if (resp.data.code === 200) {  
                    commit('SET_TOKEN', resp.data.data.login.token); //修改：data结构变了，多了菜单信息 
                    commit('SET_USER', resp.data.data.login.user);  //修改
                    commit('SET_MENUS', resp.data.data.menu);  //菜单
                    commit('SET_AVATAR', resp.data.data.login.user.avatar);  
                    addNewRoute(resp.data.data.menu);
                    return resp; 
                } else {  
                    const errorMessage = resp.data.message || '未知错误';  
                    throw new Error(`登录失败: ${errorMessage}`); // 使用模板字符串来拼接错误信息  
                }  
            } catch (error) { 
                console.error('在登录过程中发生错误:', error); // 添加额外的日志记录  
                throw error; 
            }  
        }  
    },  
    modules: {  
    }  
})

export function addNewRoute(menuList, routes = router.options.routes) {
    resetRouter();
  const existingRoutes = new Set(routes.flatMap(route => route.children || []).map(child => child.name))

  if (!Array.isArray(menuList) || menuList.length === 0) {
    console.warn('Menu list is empty or not an array.');
    return;
  }
  routes.forEach((routeItem) => {
    if (routeItem.path === '/') {
      menuList.forEach((menu) => {
        let newRoute = {
          path: menu.menupath,
          name: menu.menuname,
          meta: {
            title: menu.menutitle,
          },
          component: () => import('../views/' + menu.menucomponent),
        };
        if (menu.children && menu.children.length > 0) {
          newRoute.children = menu.children.map((child) => {
            return {
              path: child.menupath,
              name: child.menuname,
              meta: {
                title: child.menutitle,
              },
              component: () => import('../views/' + child.menucomponent),
            };
          });
            newRoute.redirect = `${newRoute.children[0].path}`;
        }
        if (!existingRoutes.has(menu.menuname)) {
          const rootRoute = routes.find(route => route.path === '/')
          if (rootRoute) {
            rootRoute.children = rootRoute.children || []
            rootRoute.children.push(newRoute)
          }
        }
      });
    }
  });
  routes.forEach((route) => {
    router.addRoute(route);
  });
  console.log(router.options.routes);
}
export default store