import router from '@/router'
import store from '@/store';

router.beforeEach((to, from, next) => {  
    if (to.path === '/login'||store.state && store.state.token) {  
      next();  
    } else {  
      console.log("当前 token 状态：", store.state.token); // 这里可能是 undefined 或 null  
      console.log("需要登录，正在重定向到登录页面");  
      next({  
        path: '/login',  
      });  
    } 
});
